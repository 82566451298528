$header-color: #710D10;
$description-color: #7D7D7D;

#landing-features-subcomponent {
    padding-top: 100px;
    padding-bottom: 100px;

    .feature-list-container {
        max-width: 1150px;
        margin-left: auto;
        margin-right: auto;

        .feature-container {
            width: 200px;
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            border-radius: 25px;
            background: linear-gradient(203.39deg, #F8F8F8 16.77%, rgba(217, 217, 217, 0) 81.45%);

            .feature-image-container {

                .feature-image {}
            }

            .feature-header {
                padding-top: 40px;
                padding-bottom: 40px;
                font-size: 30px;
                font-weight: 500;
                color: $header-color;
            }

            .feature-description {
                font-size: 13px;
                line-height: 1.5;
                color: $description-color;
            }
        }

        .desktop-view {
            max-width: 1150px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .mobile-view {
            width: 100%;
            display: none;
        }
    }
}

// Responsive Design
@media (max-width: 520px) {
    #landing-features-subcomponent {

        .feature-list-container {

            .feature-container {
                width: 250px;
                margin-left: auto;
                margin-right: auto;
                text-align: left;

                .feature-image-container {

                    .feature-image {
                        width: 50%;
                    }
                }
            }

            .desktop-view {
                display: none;
            }

            .mobile-view {
                display: block;
            }
        }
    }
}