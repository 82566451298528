#landing-hero-subcomponent {
    padding-top: 50px;
    padding-bottom: 80px;
    display: flex;

    .hero-image-container {
        width: 45%;

        .hero-image {
            width: 100%;
        }
    }

    .hero-description-container {
        width: 55%;
        padding-left: 40px;
        display: flex;

        .mobile-center-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text {
                font-size: 25px;
                color: #7D7D7D;
            }

            .welcome {
                padding-bottom: 15px;
            }

            .premier-1 {
                padding-top: 25px;
            }

            .premier-2 {}

            .journey {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            .brand-logo-container {

                .brand-logo {
                    max-width: 300px;
                }
            }

            .join-button-container {
                text-decoration: none;

                .join-button {
                    height: 50px;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    font-weight: 600;
                    background-color: #710D10;
                    color: white;
                }
            }
        }
    }
}

// Responsive Design
@media (max-width: 800px) {
    #landing-hero-subcomponent {
        flex-direction: column;

        .hero-image-container {
            width: 80%;
            padding-bottom: 40px;

            .hero-image {
                width: 100%;
            }
        }

        .hero-description-container {
            width: 100%;
            padding-left: 0;
            align-items: center;

            .mobile-center-container {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                align-items: center;

                .join-button {
                    height: 60px;
                    width: 100%;
                    font-size: 20px;
                }

                .journey {
                    text-align: center;
                }
            }
        }
    }
}