#App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .body-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
}