#landing-meet-your-date-subcomponent {
    padding-top: 50px;
    display: flex;
    background: linear-gradient(to bottom left, #e7e7e7 0%, #ffffff 50%);

    // .gradin
    .meet-your-date-description-container {
        width: 55%;
        display: flex;

        .mobile-center-container {
            padding-left: 20%;
            padding-right: 50px;
            padding-bottom: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .header {
                padding-top: 30px;
                font-size: 40px;
                font-weight: 500;
                color: #710D10;
            }
            
            .text {
                padding-top: 30px;
                font-size: 23px;
                color: #7D7D7D;
            }
        }
    }

    .meet-your-date-image-container {
        width: 45%;

        .meet-your-date {
            width: 100%;
        }
    }
}

// Responsive Design
@media (max-width: 800px) {
    #landing-meet-your-date-subcomponent {
        flex-direction: column-reverse;
        background: linear-gradient(to bottom left, #e7e7e7 0%, #ffffff 100%);

        .meet-your-date-description-container {
            width: 100%;
            padding-bottom: 40px;

            .mobile-center-container {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                padding: 0;
            }
        }

        .meet-your-date-image-container {
            width: 80%;
            margin-left: auto;

            .meet-your-date {
                width: 100%;
            }
        }
    }
}