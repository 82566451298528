$main-color: #710D0D;

#navbar {
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;

    .space-around {
        width: 100%;
        max-width: 1020px;
        padding: 0 12px;

        .desktop-view {
            height: 100%;
            display: flex;
            justify-content: space-between;

            .brand-logo-container {
                display: flex;
                align-items: center;

                .brand-logo {
                    max-width: 200px;
                }
            }

            .navigation-right-container {
                display: flex;
                justify-content: center;

                .info-item {
                    padding: 0 30px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 600;
                    color: $main-color;
                }
            }
        }

        .mobile-view {
            height: 100%;
            display: none;
            justify-content: space-between;

            .left-container {
                width: 20%;
            }

            .brand-logo-container {
                width: 60%;
                display: flex;
                justify-content: center;
                align-items: center;

                .brand-logo {
                    max-width: 200px;
                }
            }

            .burger-menu-container {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;

                .burger-menu {
                    width: 30px;
                }
            }
        }
    }
}

// Utility CSS
.burger-menu-list-link {
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;

    .burger-menu-list-text {
        padding-top: 10px;
        padding-bottom: 10px;
        color: $main-color;
    
        // Override Material UI Typography
        .MuiTypography-root {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

// Responsive Design
@media (max-width: 800px) {
    #navbar {

        .space-around {

            .desktop-view {

                .navigation-right-container {

                    .info-item {
                        padding: 0 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 680px) {
    #navbar {

        .space-around {

            .desktop-view {
                display: none;

                .navigation-right-container {
                    display: none;
                }

                .burger-menu-container {
                    padding-right: 45px;
                    display: flex;
                }
            }

            .mobile-view {
                display: flex;
            }
        }
    }
}